import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import Register from './components/Register';
import Chat from './components/Chat';
import Header from './components/Header';

const useLogin = false; // Flag para activar/desactivar el login

function App() {
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [showLogin, setShowLogin] = useState(true);

  const handleLogin = (token, username) => {
    setToken(token);
    setUsername(username);
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
  };

  const handleLogout = () => {
    setToken(null);
    setUsername('');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
  };

  const toggleAuthMode = () => {
    setShowLogin(!showLogin);
  };

  if (!token) {
    return showLogin ? (
      <div>
        <Header onLogout={handleLogout}/>
        <Login onLogin={handleLogin} toggleAuthMode={toggleAuthMode}/>
        {/* <button onClick={toggleAuthMode}>Register</button> */}
      </div>
    ) : (
      <div>
        <Header onLogout={handleLogout}/>
        <Register onLogin={handleLogin} toggleAuthMode={toggleAuthMode}/>
        {/* <button onClick={toggleAuthMode}>Login</button> */}
      </div>
    );
  }

  return (
    <div>
      <Header onLogout={handleLogout}/>
      <Chat username={username} />
    </div>
  );

  return (
    <div className="App">
      <Header />
      <Chat/>
    </div>
  );
}

export default App;
