import React, { useState } from 'react';
import Header from './Header';

function Register({ onLogin, toggleAuthMode }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://chat.bodadejoseangelymarta.com/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    if (response.ok) {
      // Auto-login after successful registration
      alert(`Registro exitoso. Bienvenido, ${username}!`);

      const loginResponse = await fetch('https://chat.bodadejoseangelymarta.com/api/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          username,
          password,
        }),
      });

      const loginData = await loginResponse.json();
      if (loginResponse.ok) {
        onLogin(loginData.access_token, username);
      } else {
        alert('Auto-login failed!');
      }
    } else {
      alert(`Registration failed: ${data.detail}`);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2 className="login-title">Registrar</h2>
        <div className="form-group">
          <label htmlFor="username">Usuario</label>
          <input
            type="text"
            id="username"
            placeholder="Introduce tu usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            id="password"
            placeholder="Introduce tu contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">Registrar</button>
      </form>
      <div className="register-container">
        <p>¿Ya tienes cuenta?</p>
        <button onClick={toggleAuthMode} className="register-button">Iniciar sesión</button>
      </div>
    </div>
  );
}

export default Register;
