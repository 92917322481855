import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoFilm, faScroll, faMicrophoneLines, faUsers, faBars, faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const Header = ({onLogout}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div>
      <header className="header d-flex justify-content-between align-items-center p-3" style={{ borderBottom: '2px solid gold'}}>
        <div className="d-flex align-items-center">
          <button onClick={toggleMenu} className="btn btn-link" style={{ color:'#1d4f5f', fontSize:'20px'}}>
          <FontAwesomeIcon icon={faBars} /> {/* Icono de las tres rayas */}
          </button>
          <h1 className="mb-0">Chat Boda MyJ</h1>
          <a href="https://fotosqr.bodadejoseangelymarta.com/" style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 'bold', color: '#6db6bb', backgroundColor:'#1d4f5f', borderRadius:'8px', marginLeft: '20px', padding:'2px 4px' }}>Salir</a>
        </div>
      </header>

      {/* Sidebar */}
      <div
        className={` sidebar p-4 position-fixed top-0 ${
          menuVisible ? 'd-block' : 'd-none'
        }`}
        style={{
          width: '250px',
          height: '100vh',
          padding: '20px',
          backgroundColor: '#6db6bb',
          transition: 'left 0.3s ease',
          zIndex: '1000',
          borderRight: '1px solid #d4af36'
        }}
      >
        <button
          onClick={toggleMenu}
          className="btn btn-link text-dark mb-4"
          style={{ position: 'absolute', right: '10px', top: '10px' }}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '20px', fontWeight: 'bold', color: '#1d4f5f' }}/> {/* Flecha para ocultar el menú */}
        </button>
        <ul className="list-unstyled mt-5">
          <li className="mt-4"><a href="https://fotosqr.bodadejoseangelymarta.com/" style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 'bold', color: '#1d4f5f' }}><FontAwesomeIcon icon={faPhotoFilm} /> Galería</a></li>
          <li className="mt-4"><a href="https://fotosqr.bodadejoseangelymarta.com/dedications" style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 'bold', color: '#1d4f5f' }}><FontAwesomeIcon icon={faScroll} /> Dedicatorias</a></li>
          <li className="mt-4"><a href="https://fotosqr.bodadejoseangelymarta.com/voice_notes" style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 'bold', color: '#1d4f5f' }}><FontAwesomeIcon icon={faMicrophoneLines} /> Notas de voz</a></li>
          <li className="mt-4"><a href="https://fotosqr.bodadejoseangelymarta.com/personas" style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 'bold', color: '#1d4f5f' }}><FontAwesomeIcon icon={faUsers} /> Buscate</a></li>
          <li className="mt-4"><a
              href="#logout"
              onClick={onLogout}
              style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 'bold', color: '#1d4f5f' }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} /> Cerrar sesión
            </a></li>
        </ul>
      </div>
    </div>
  );
};
export default Header;
