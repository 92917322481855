import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes, faReply } from '@fortawesome/free-solid-svg-icons';


function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

function Chat({ username }) {
  // const [username, setUsername] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(null);
  const typingInterval = useRef(null);
  const [ws, setWs] = useState(null);
  const messageEndRef = useRef(null);
  const [selectedMessage, setSelectedMessage] = useState(null);


  // useEffect(() => {
  //   if (!username) {
  //     const user = prompt('Introduce tu nombre de usuario:', 'admin');
  //     setUsername(user);
  //   }
  // }, [username]);

  useEffect(() => {
    if (username) {

      const fetchMessages = async () => {
        const response = await fetch('/messages');
        const data = await response.json();
        console.log(data)
        //setMessages(data.reverse());  // Reversa para mostrar los más antiguos primero
        if (data.length > 0) {
          const reversedData = [...data].reverse();
          setMessages(reversedData);
        } else {
          console.log('No messages to load.');
        }
      };
    
      fetchMessages();

      const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/${username}`);
      setWs(socket);

      socket.onmessage = (event) => {
        const msg = JSON.parse(event.data);
        //setMessages((prevMessages) => [...prevMessages, msg]);
        if (msg.type === "typing") {
          setTyping(msg.sender);
          setTimeout(() => setTyping(null), 3000); // Clear "typing" after 3 seconds
        } else {
          setMessages((prevMessages) => [...prevMessages, msg]);
        }
      };

      return () => socket.close();
    }
  }, [username]);

  const sendMessage = (messageType = "text") => {
    
    if (ws && inputValue.trim()) {
      const message = {
        sender: username,
        content: inputValue,
        message_type: "text",
        timestamp: new Date().toLocaleTimeString(), // Añadir la hora al mensaje
        reply_to: selectedMessage ? selectedMessage.id : null,

      };
      console.log(message)
      ws.send(JSON.stringify(message));
      //ws.send(inputValue);
      //setMessages([...messages, `${username}: ${inputValue}`]);
      setInputValue('');
      setSelectedMessage(null);
    }
  };


  const handleTyping = () => {
    if (!typingInterval.current) {
      ws.send("__typing__");
      typingInterval.current = setInterval(() => {
        if (inputValue.trim()) {
          ws.send("__typing__");
        } else {
          clearInterval(typingInterval.current);
          typingInterval.current = null;
        }
      }, 5000);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      clearInterval(typingInterval.current);
      typingInterval.current = null;
    }
  };

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="message-list">
      {messages.map((msg, index) => {
          //const [sender, message] = msg.split(': ', 2);
          const isSent = msg.sender === username;
          return (
            <div key={index} className={`message ${isSent ? 'sent' : 'received'}`}>
              {msg.reply_to && (
              <div className="reply-to">
                <strong>Respuesta a:</strong> {messages.find(m => m.id === msg.reply_to)?.content}
              </div>
            )}
              {!isSent && <span className="sender" style={{ color: msg.color }}>{msg.sender}</span>}
              <span>{msg.message_type === "text" && msg.content}</span>
              <span>{msg.message_type === "gif" && <img src={msg.content} alt="GIF" />}</span>
              <div className="testtest" style={{display:'flex', alignContent:'space-between'}}>
                <div className="timestamp">{formatTimestamp(msg.timestamp)}</div>
                <button className="cancel-button" onClick={() => setSelectedMessage(msg)}><FontAwesomeIcon icon={faReply} className={`${isSent ? 'reply-left' : 'reply-right'}`}/></button>
              </div>
           </div>
          );
        })}
        {typing && <div className="typing-indicator">{typing} esta escribiendo...</div>}
        <div ref={messageEndRef} />
      </div>
      {selectedMessage && (
          <div className="quoted-message">
            Respondiendo a: {selectedMessage.content}
            <button className="cancel-button" onClick={() => setSelectedMessage(null)}><FontAwesomeIcon icon={faTimes} /></button>
          </div>
        )}
      <div className="message-input-container">
        <input
          type="text"
          className="message-input"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          onInput={handleTyping}
          placeholder="Escribe tu mensaje..."
        />
        <button className="send-button" onClick={sendMessage}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
}

export default Chat;
